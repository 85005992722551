import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

import { enumFeatureToggle } from '@cdux/ng-common';

import { AccountHistoryContainerComponent } from './account/account-history/account-history-container.component';
import { AppPreloadStrategy } from './app.preload-strategy';
import { AuthGuard, FeatureToggleGuard, PlatformGuard } from './shared/common/guards';
import { DEFAULT_REDIRECT_PATH, SSO_LOGIN_PATH  } from './app.routing.constants';
import { EzbankIframeComponent } from './account/deposit/onlinebanking/ezbank-iframe.component';
import { FullPageFundingComponent } from 'app/shared/funding/full-page-funding/full-page-funding.component';
import { FullPageFundingConstants } from 'app/shared/funding/full-page-funding/full-page-funding.constants';
import { FullpageRegistrationComponent } from './shared/registration/fullpage-registration.component';
import { LayoutHeaderFooterComponent } from './shared/layout/components/layout-header-footer/layout-header-footer.component';

import { BetPadWindowGuard } from 'app/bet-pad-window.guard';
import { TournamentGuard, TournamentGuardChild } from './shared/common/guards/tournaments/tournament-guards';
import { InboxMenuItemComponent } from "./shared/menu-items/components/inbox/inbox-menu-item.component";

export const Routes: Route[] = [
    {
        path: '',
        component: LayoutHeaderFooterComponent,
        children: [
            {
                path: 'account',
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
                children: [
                    {
                        path: 'deposit',
                        children: [
                            { path: 'greendot', loadChildren: () => import('app/account/deposit/greendot/greendot-atr.module').then(m => m.GreenDotATRModule) },
                            {
                                path: 'onlinebanking',
                                component: EzbankIframeComponent
                            }
                        ],
                        canActivate: [BetPadWindowGuard],
                    },
                    {
                        path: 'history',
                        component: AccountHistoryContainerComponent,
                    }
                ],
            },
            {
                path: 'calendar',
                loadChildren: () => import('app/calendar/calendar.module').then(m => m.CalendarModule),
                canActivate: [FeatureToggleGuard, TournamentGuard],
                data: {
                    featureToggle: enumFeatureToggle.CALENDAR,
                    redirectUrl: DEFAULT_REDIRECT_PATH
                }
            },
            {
                path: 'carryovers',
                loadChildren: () => import('app/carryovers/carryovers.module').then(m => m.CarryoversModule),
                canActivate: [FeatureToggleGuard, TournamentGuard],
                data: {
                    featureToggle: enumFeatureToggle.CARRYOVERS,
                    redirectUrl: DEFAULT_REDIRECT_PATH
                }
            },
            {
                path: 'changes', loadChildren: () => import('app/wagering/track-changes/track-changes.module').then(m => m.TrackChangesModule)
            },
            {
                path: 'free-bets', loadChildren: () => import('app/free-bets/free-bets.module').then(m => m.FreeBetsModule)
            },
            {
                path: 'handicapping',
                loadChildren: () => import('app/tsui/tsui.routing.module').then(m => m.TSUIRoutingModule),
                canActivate: [FeatureToggleGuard, TournamentGuard],
                data: {
                    featureToggle: enumFeatureToggle.HANDICAPPING,
                    redirectUrl: DEFAULT_REDIRECT_PATH
                }
            },
            {
                path: 'leaderboard', loadChildren: () => import('app/leaderboard-contest/leaderboard-contest.module').then(m => m.LeaderboardContestModule)
            },
            {
                path: 'tournaments', loadChildren: () => import('app/tournaments/tournaments.module').then(m => m.TournamentsModule)
            },
            {
                path: 'offers',
                loadChildren: () => import('app/offers/offers.module').then(m => m.OffersModule),
                canActivateChild: [TournamentGuardChild]
            },
            {
                path: 'privacy', loadChildren: () => import('app/privacy/privacy.module').then(m => m.PrivacyModule)
            },
            {
                path: 'privacy-settings', loadChildren: () => import('app/privacy-settings/privacy-settings.module').then(m => m.PrivacySettingsModule)
            },
            {
                path: 'product', loadChildren: () => import('app/ecommerce/ecommerce.module').then(m => m.EcommerceModule)
            },
            {
                path: 'registration', pathMatch: 'prefix', redirectTo: '/join-now'
            },
            {
                path: 'results', loadChildren: () => import('app/wagering/results/results.module').then(m => m.ResultsModule)
            },
            {
                path: 'support', loadChildren: () => import('app/support/support.module').then(m => m.SupportModule)
            },
            {
                path: 'todays-races', loadChildren: () => import('app/wagering/todays-races/todays-races.module').then(m => m.TodaysRacesModule)
            },
            {
                path: '', pathMatch: 'full', redirectTo: DEFAULT_REDIRECT_PATH
            }
        ]
    },

    {
        path: 'analytics',
        loadChildren: () => import('app/analytics/analytics.module').then(m => m.AnalyticsModule)
    },
    {
        path: 'betpad',
        data: {
            featureToggle: enumFeatureToggle.BETPAD,
            redirectUrl: DEFAULT_REDIRECT_PATH
        },
        canActivate: [FeatureToggleGuard, AuthGuard],
        loadChildren: () => import('app/bet-pad/bet-pad.module').then(m => m.BetPadModule)
    },
    {
        path: 'betshare',
        loadChildren: () => import('app/bet-share/bet-share.module').then(m => m.BetShareModule)
    },
    {
        path: 'classic',
        data: {
            featureToggle: enumFeatureToggle.CLASSIC_VIEW,
            redirectUrl: DEFAULT_REDIRECT_PATH,
            disableThemes: true
        },
        canActivate: [FeatureToggleGuard, AuthGuard],
        loadChildren: () => import('app/classic/classic.module').then(m => m.ClassicModule)
    },
    {
        path: 'funding/:operation/:method', // when converted to upper case, :method must match a key to FUND_ID enum
        component: FullPageFundingComponent,
        canActivate: [FeatureToggleGuard, AuthGuard, TournamentGuard],
        data: {
            featureToggle: 'FULL_PG_DEPOSIT',
        },
    },
    {
        path: 'funding/:operation',
        redirectTo: '/funding/:operation/' + FullPageFundingConstants.ROUTE_PARAM_DUMMY,
    },
    {
        path: 'deposit/:method',
        redirectTo: 'funding/deposit/:method'
    },
    {
        path: 'deposit',
        redirectTo: 'funding/deposit/' + FullPageFundingConstants.ROUTE_PARAM_DUMMY,
    },
    {
        path: 'withdraw/:method',
        redirectTo: 'funding/withdraw/:method'
    },
    {
        path: 'withdraw',
        redirectTo: 'funding/withdraw/' + FullPageFundingConstants.ROUTE_PARAM_DUMMY,
    },
    {
        path: 'funding',
        redirectTo: 'funding/deposit/' + FullPageFundingConstants.ROUTE_PARAM_DUMMY,
    },
    {
        path: 'express',
        data: {
            featureToggle: enumFeatureToggle.EXPRESS,
            redirectUrl: DEFAULT_REDIRECT_PATH,
            disableThemes: true
        },
        canActivate: [FeatureToggleGuard, AuthGuard],
        loadChildren: () => import('app/express/express.routing.module').then(m => m.ExpressRoutingModule)
    },
    {
        path: 'join-now/:code',
        component: FullpageRegistrationComponent,
        data: {
            featureToggle: enumFeatureToggle.ENABLE_SSO_LOGIN,
            negateFeatureToggle: true,
            redirectUrl: SSO_LOGIN_PATH
        },
        canActivate: [FeatureToggleGuard]
    },
    {
        path: 'join-now',
        component: FullpageRegistrationComponent,
        data: {
            featureToggle: enumFeatureToggle.ENABLE_SSO_LOGIN,
            negateFeatureToggle: true,
            redirectUrl: SSO_LOGIN_PATH
        },
        canActivate: [FeatureToggleGuard]
    },
    {
        path: 'login', loadChildren: () => import('app/security/login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'tournaments-selection',
        loadChildren: () => import('app/shared/tournaments-session/tournaments-session.routing.module').then(m => m.TournamentSessionRoutingModule),
        canActivate: [FeatureToggleGuard, AuthGuard],
        data: {
            featureToggle: enumFeatureToggle.SHOW_TOURNAMENTS,
            redirectUrl: DEFAULT_REDIRECT_PATH
        }

    },
    {
        path: 'password',
        children: [
            {
                path: 'forgot', loadChildren: () => import('app/security/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
            },
            {
                path: 'reset', loadChildren: () => import('app/security/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
            },
        ],
    },
    {
        path: 'platform',
        children: [
            {
                path: 'registration',
                loadChildren: () => import('app/platform/registration/platform-registration.module').then(m => m.PlatformRegistrationModule)
            },
            {
                path: 'profile-update',
                loadChildren: () => import('app/platform/profile-update/platform-profile-update.module').then(m => m.PlatformProfileUpdateModule)
            }
        ]
    },
    {
        path: 'program',
        data: {
            showMobileVideoButton: true,
        },
        loadChildren: () => import('app/wagering/views/views.module').then(m => m.WageringViewsModule)
    },
    {
        path: 'stand-alone',
        children: [
            {
                path: 'calendar', loadChildren: () => import('app/calendar/calendar.module').then(m => m.CalendarModule)
            },
            {
                path: 'registration/:id/id-scan', loadChildren: () => import('app/id-scan-standalone/id-scan-standalone.module').then(m => m.IdScanStandaloneModule),
                canActivate: [FeatureToggleGuard, PlatformGuard],
                data: {
                    featureToggle: enumFeatureToggle.IDSCAN_STANDALONE,
                    suppressPreload: true
                }
            }
        ]
    },
    {
        path: 'doc-manager', loadChildren: () => import('app/doc-manager/doc-manager.module').then(m => m.DocManagerModule)
    },
    {
        path: 'verify', loadChildren: () => import('app/verify/verify.module').then(m => m.VerifyModule)
    },
    {
        path: 'video', loadChildren: () => import('app/video-standalone/video-standalone.module').then(m => m.VideoStandaloneModule)
    },
    {
        path: 'inbox',
        component: LayoutHeaderFooterComponent,
        canActivate: [ FeatureToggleGuard, AuthGuard ],
        canActivateChild: [ AuthGuard ],
        data: {
            featureToggle: enumFeatureToggle.INBOX
        },
        children: [
            {
                path: 'cards',
                component: InboxMenuItemComponent
            } //The full pass of the access url will be /bet/inbox/cards?action=inbox
        ]
    },
    {
        path: '**',
        redirectTo: DEFAULT_REDIRECT_PATH
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            Routes,
            {
                preloadingStrategy: AppPreloadStrategy,
                relativeLinkResolution: 'legacy' // TODO: is this necessary?
            },
        ),
    ],
    exports: [
        RouterModule,
    ],
})
export class AppRoutingModule {
    // Empty
}
